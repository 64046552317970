import React from 'react'
import Auth from 'components/Auth'
import Box from 'components/Box'
import Container from 'components/Container'
import Head from 'components/Head'
import Layout from 'components/Layout'
import View from 'components/View'
import useEventPageVisited from 'hooks/use-event-page-visited'

export default function SignIn() {
  useEventPageVisited('Sign in')

  return (
    <Layout className="SignIn" data-test-id="sign-in">
      <View flush>
        <Head
          title="Log in to Bold — Variety of online fitness classes for older adults"
          description="Bold is your home for personalized, science-backed workouts shown to reduce fall risk. Assess your strength, mobility, and balance with a free 1-minute test."
        />
        <Container size="xlarge">
          <Box flush>
            <Auth type="sign-in" />
          </Box>
        </Container>
      </View>
    </Layout>
  )
}
